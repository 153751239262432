import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

const routes_pc = [
  {
    path: '*',
    redirect: '/index'
  },

  {
    path: '/index',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/pc/index.vue'),
        meta: {
          headerIsShow: true,
          // footerIsShow: true,
        }
      },
      {
        path: '/adultService',
        name: 'adultService',
        component: () => import('../views/pc/trainService/adultService')
      },
      {
        path: '/readOnline',
        name: 'readOnline',
        component: () => import('../views/pc/trainService/readOnline')
      },
      {
        path: '/centerIntroduction',
        name: 'centerIntroduction',
        component: () => import('../views/pc/trainService/centerIntroduction')
      },
      {
        path: '/noticeDetail',
        name: 'noticeDetail',
        component: () => import('../views/pc/trainService/noticeDetail')
      },
      {
        path: '/achievement',
        name: 'achievement',
        component: () => import('../views/pc/trainService/achievement')
      },
      {
        path: '/examList',
        name: 'examList',
        component: () => import('../views/pc/trainService/examList')
      },
      {
        path: '/enrollment',
        name: 'enrollment',
        component: () => import('../views/pc/trainService/enrollment')
      },
      {
        path: '/gymService',
        name: 'gymService',
        component: () => import('../views/pc/gym/service')
      },
      {
        path: '/gymRoom',
        name: 'gymRoom',
        component: () => import('../views/pc/gym/gymRoom')
      },
      {
        path: '/gymActivity',
        name: 'gymActivity',
        component: () => import('../views/pc/gym/activity')
      },
      {
        path: '/lawServe',
        name: 'lawServe',
        component: () => import('../views/pc/law/lawServe')
      },
      {
        path: '/lawConsult',
        name: 'lawConsult',
        component: () => import('../views/pc/law/lawConsult')
      },{
        path: '/lawList',
        name: 'lawList',
        component: () => import('../views/pc/law/lawList')
      },
      {
        path: '/innovationBase',
        name: 'innovationBase',
        component: () => import('../views/pc/base/innovationBase')
      },{
        path: '/unionBase',
        name: 'unionBase',
        component: () => import('../views/pc/base/unionBase')
      },
      // 申请入驻
      {
        path: '/applyIndex',
        name: 'applyIndex',
        component: () => import('../views/pc/base/applyIndex')
      },
      {
        path: '/cinema',
        name: 'cinema',
        component: () => import('../views/pc/cinema')
      },
    ]
  }
]
const routes_m = [
  
  {
    path: '*',
    redirect: '/index'
  },

  {
    path: '/index',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/m/index.vue'),
        meta: {
          headerIsShow: true,
          // footerIsShow: true,
        }
      },
      {
        path: '/adultService',
        name: 'adultService',
        component: () => import('../views/m/trainService/adultService')
      },
      {
        path: '/centerIntroduction',
        name: 'centerIntroduction',
        component: () => import('../views/m/trainService/centerIntroduction')
      },
      {
        path: '/noticeDetail',
        name: 'noticeDetail',
        component: () => import('../views/m/trainService/noticeDetail')
      },
      {
        path: '/examList',
        name: 'examList',
        component: () => import('../views/m/trainService/examList')
      },
      {
        path: '/gymService',
        name: 'gymService',
        component: () => import('../views/m/gym/service')
      },
      {
        path: '/lawServe',
        name: 'lawServe',
        component: () => import('../views/m/law/lawServe')
      },
      {
        path: '/lawConsult',
        name: 'lawConsult',
        component: () => import('../views/m/law/lawConsult')
      },{
        path: '/lawList',
        name: 'lawList',
        component: () => import('../views/m/law/lawList')
      },
      {
        path: '/innovationBase',
        name: 'innovationBase',
        component: () => import('../views/m/base/innovationBase')
      },{
        path: '/unionBase',
        name: 'unionBase',
        component: () => import('../views/m/base/unionBase')
      },
      {
        path: '/cinema',
        name: 'cinema',
        component: () => import('../views/m/cinema')
      },{
        path: '/applyIndex',
        name: 'applyIndex',
        component: () => import('../views/m/base/applyIndex')
      },{
        path: '/uploadIndex',
        name: 'uploadIndex',
        component: () => import('../views/m/base/uploadIndex')
      },
    ]
  }
]
const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes: IsPC() ? routes_pc : routes_m
})

export default router
