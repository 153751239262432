<template>
  <div class="header_box">
    <div class="heade_cont flex">
      <div class="logo"><img src="@/assets/logo.png" alt="" /></div>
      <ul class="nav_cont_list flex">
        <li>
          <router-link active-class="activeMenu" to="/index">首页</router-link>
        </li>

        <li class="sec_box">
          <!-- <a href="55">培训服务</a> -->
          <router-link to="">培训服务</router-link>
          <div class="sec_item serveSec">
            <ul>
              <p>职业技能</p>
              <li>
                <router-link active-class="activeColor" to="/adultService"
                  >职工教育</router-link
                >
              </li>
              <li>
                <router-link active-class="activeColor" to="/readOnline"
                  >在线报读</router-link
                >
              </li>
              <li>
                <a
                  href="https://gzycwhg.kskstudy.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >职业技能培训</a
                >
              </li>
            </ul>
            <ul>
              <p>少儿培训</p>
              <li>
                <router-link active-class="activeColor" to="/centerIntroduction"
                  >中心介绍</router-link
                >
              </li>
              <li>
                <router-link active-class="activeColor" to="/achievement"
                  >成果展示</router-link
                >
              </li>
              <li>
                <router-link active-class="activeColor" to="/enrollment"
                  >招生报名</router-link
                >
              </li>
            </ul>
            <!-- <ul>
              <p>职业技能</p>
              <li>
                <a href="https://gzycwhg.kskstudy.com/" target="_blank" rel="noopener noreferrer">职业技能培训</a>
              </li>
            </ul> -->
          </div>
        </li>
        <li class="third_box">
          <router-link active-class="activeMenu" to="/gymService"
            >员宫体育馆</router-link
          >
          <div class="sec_item thirdGym">
            <ul>
              <li>
                <router-link active-class="activeColor" to="/gymService"
                  >服务内容</router-link
                >
              </li>
              <li>
                <router-link active-class="activeColor" to="/gymActivity"
                  >活动剪影</router-link
                >
              </li>
              <!-- <li>
                <router-link active-class="activeColor" to="/gymRoom"
                  >职工健身房</router-link
                >
              </li> -->
            </ul>
          </div>
        </li>
        <li>
          <router-link active-class="activeMenu" to="/lawServe"
            >法律服务</router-link
          >
        </li>
        <li class="base_nav">
          <router-link active-class="activeMenu" to="/innovationBase"
            >创新创业</router-link
          >
          <!-- <div class="sec_item baseBox">
            <ul>
              <li>
                <router-link active-class="activeColor" to="/innovationBase"
                  >基层工会建设创新基地</router-link
                >
              </li>
              <li>
                <router-link active-class="activeColor" to="/innovationBase"
                  >粤港澳大湾区创新创业基地</router-link
                >
              </li>
            </ul>
          </div> -->
        </li>
        
        <li>
          <router-link active-class="activeMenu" to="/unionBase"
            >基层工会建设创新基地</router-link
          >
        </li>
        <li class="third_box">
          <router-link to="/cinema">配套服务</router-link>

          <div class="sec_item thirdGym">
            <ul>
              <li>
                <router-link active-class="activeColor" to="/cinema"
                  >大地影院</router-link
                >
              </li>
              <li>
                <router-link active-class="activeColor" to="/gymRoom"
                  >职工健身房</router-link
                >
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div class="userInfo" v-if="userInfo">
            <p class="name">测试1</p>
            <p class="out_login cursor" @click="logOutBtn()">退出</p>
          </div>
          <a href="javascript:;" v-else @click="showMask()">会员中心</a>
        </li>
      </ul>
    </div>

    <login-way v-if="loginModal.show"></login-way>
  </div>
</template>

<script>
import loginWay from '@/components/pc/login.vue';
import { mapState, mapMutations } from "vuex";
export default {
  name: "header",
  components:{
    loginWay
  },
  computed: {
    ...mapState(["tipModal", "loginModal", "userInfo"]),
  },
  data() {
    return {
      maskBoxState: false,
      maskBoxIndex: 1,
      userInfo: "",
    };
  },
  mounted() {
    if(sessionStorage.getItem('userData')){
      this.userInfo = JSON.parse(sessionStorage.getItem('userData'))
    }
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "ShowTipModal"]), //登录弹窗
    // 点击会员中心
    showMask() {
      // this.maskBoxState = true;
      // this.maskBoxIndex = 1;
      this.ShowLoginModal()
    },
    // 登录
    loginBtn() {
      if (!this.loginData.username) {
        this.$message.error("请输入您的账号");
        return;
      }
      if (!this.loginData.password) {
        this.$message.error("请输入您的密码");
        return;
      }
      // let form = new FormData(this.loginData);
      // form.append("username", this.loginData.username);
      // form.append("password", this.loginData.password);
      console.log(this.getFormData(this.loginData))
      let form = this.getFormData(this.loginData)
      this.$axios.post(`/user/login`, form).then((res) => {
        if (res.code == 1) {
          this.$message.success("登录成功");
          this.userInfo = res.data
          this.closeMask()
          sessionStorage.setItem('userData',JSON.stringify(res.data))
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 忘记密码
    forgetBtn() {
      let reg = /^0?1[3-9][0-9]\d{8}$/;
      if (!this.forgetData.name) {
        this.$message.error("请输入您的姓名");
        return;
      }
      if (!this.forgetData.phone) {
        this.$message.error("请输入您的手机号");
        return;
      }
      if (!reg.test(this.forgetData.phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.forgetData.password) {
        this.$message.error("请输入您的密码");
        return;
      }
      if (!this.forgetData.reset_password) {
        this.$message.error("请再次输入您的密码");
        return;
      }
      if (this.forgetData.password != this.forgetData.reset_password) {
        this.$message.error("两次密码不一致");
        return;
      }
       let form = this.getFormData(this.forgetData)
      this.$axios.post(`/user/reset_password`, form).then((res) => {
        if (res.code == 1) {
          this.$message.success("修改成功");
          this.maskBoxIndex=1
          for (let i in this.forgetData) {
            this.forgetData[i] = "";
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 注册
    registerBtn() {
      let reg = /^0?1[3-9][0-9]\d{8}$/;
      if (!this.registerData.username) {
        this.$message.error("请输入您的账号");
        return;
      }
      if (!this.registerData.password) {
        this.$message.error("请输入您的密码");
        return;
      }
      if (!this.registerData.phone) {
        this.$message.error("请输入您的手机号");
        return;
      }
      if (!reg.test(this.registerData.phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.registerData.name) {
        this.$message.error("请输入您的姓名");
        return;
      }
      if (!this.registerData.company) {
        this.$message.error("请输入您的单位");
        return;
      }
      if (!this.registerData.job) {
        this.$message.error("请输入您的职务");
        return;
      }
      let form = this.getFormData(this.registerData)
      this.$axios.post(`/user/register`, form).then((res) => {
        if (res.code == 1) {
          this.$message.success("注册成功");
         this.maskBoxIndex=1
          for (let i in this.registerData) {
            this.registerData[i] = "";
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 退出登录
    logOutBtn() {
      this.userInfo=''
      sessionStorage.removeItem('userData')
    },
    // 关闭mask弹框
    closeMask() {
      this.maskBoxState = false;
      for (let i in this.loginData) {
        this.loginData[i] = "";
      }
      for (let i in this.registerData) {
        this.registerData[i] = "";
      }
      for (let i in this.forgetData) {
        this.forgetData[i] = "";
      }
    },

    getFormData(object) {
      const formData = new FormData();
      Object.keys(object).forEach((key) => {
        const value = object[key];
        if (Array.isArray(value)) {
          value.forEach((subValue, i) =>
            formData.append(key + `[${i}]`, subValue)
          );
        } else {
          formData.append(key, object[key]);
        }
      });
      console.log(formData,'formData')
      return formData;
    },
  },
};
</script>

<style scoped lang="scss">
.header_box {
  height: 80px;
  background: #3467c4;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  margin-bottom: 80px;
  .heade_cont {
    width: 1200px;
    margin: 0 auto;
    height: 80px;
    div.logo {
      img {
        height: 36px;
        width: auto;
      }
    }
    ul.nav_cont_list {
      margin-left: 40px;
      flex: 1;
      justify-content: space-between;
      > li:first-child {
        width: 60px;
      }
      > li {
        // flex: 1;
        text-align: center;
        font-size: 18px;
        position: relative;
        height: 80px;
        line-height: 80px;
        // margin-right: 20px;
        a {
          color: #fff;
        }
        .activeMenu::after {
          content: "";
          width: 64px;
          height: 3px;
          display: block;
          margin: 0 auto;
          position: relative;
          bottom: 3px;
          left: 0;
          background-color: #fff;
          border-radius: 30px;
        }
        .activeMenu {
          font-weight: bold;
        }
        .sec_item {
          position: absolute;
          top: 80px;
          left: 0;
          z-index: 999;
          background: rgba(35, 57, 227, 0.8);
          li {
            font-size: 16px;
            color: #ffffff;
          }
          .activeColor {
            font-weight: bold;
            color: #ffd533;
          }
        }
        // 培训服务二级菜单
        .serveSec {
          width: 548px;
          min-height: 140px;
          display: none;
          ul {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            height: 70px;
            p {
              margin-left: 13px;
              font-size: 16px;
              color: #ffd533;
              margin-right: 30px;
            }
            li {
              margin-right: 20px;
            }
          }
          ul:last-child {
            border: none;
          }
        }
        // 员宫体育馆二级菜单
        .thirdGym,
        .baseBox {
          width: 170px;
          // height: 140px;
          display: none;
          left: -40px;
          li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            height: 70px;
            line-height: 70px;
          }
          li:last-child {
            border: none;
          }
        }
        .baseBox {
          width: 288px;
        }

        .userInfo {
          display: flex;
          align-items: center;
          p {
            color: #fff;
            margin-right: 10px;
            font-size: 14px;
          }
        }
      }
      > li:last-child {
        // flex: 3;
      }
      .sec_box:hover {
        .serveSec {
          display: block;
        }
      }
      .third_box:hover {
        .thirdGym {
          display: block;
        }
      }
      .base_nav:hover {
        .baseBox {
          display: block;
        }
      }
    }
  }
}


::-webkit-input-placeholder{/*Webkit browsers*/
	font-size:16px;
}
:-moz-placeholder{/*Mozilla Firefox 4 to 8*/
	font-size:16px;
}
::moz-placeholder{/*Mozilla Firefox 19+*/
	font-size:16px;
}
:-ms-input-placeholder{/*Internet Explorer 10+*/
    font-size:16px;
}
</style>