<template>
  <!-- 登录弹窗 -->
  <div class="log-modal">
     <div class="mask_box">
      <!-- 登录 -->
      <div class="mask_cont_box mask_cont" v-if="maskBoxIndex == 1">
        <div class="head_text">
          <p class="close" @click="closeMask()">
            <img src="@/assets/vip/close_icon.png" alt="" />
          </p>
          <p class="text">个人会员登录</p>
        </div>
        <div class="form_cont">
          <ul>
            <li>
              <p class="icon"><img src="@/assets/vip/icon1.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="loginData.username"
                  placeholder="请输入您的账号"
                />
              </p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/vip/icon2.png" alt="" /></p>
              <p class="input">
                <input
                  type="password"
                  v-model="loginData.password"
                  placeholder="请输入您的密码"
                />
              </p>
            </li>
          </ul>
        </div>
        <div class="sign_btn cursor" @click="loginBtn()">登录</div>
        <div class="deep_cont flex">
          <p>没有账号？<span @click="maskBoxIndex = 3">去注册</span></p>
          <p class="cursor" @click="maskBoxIndex = 2">忘记密码</p>
        </div>
      </div>
      <!-- 忘记密码 -->
      <div class="mask_cont_forget mask_cont" v-if="maskBoxIndex == 2">
        <div class="head_text">
          <p class="close" @click="closeMask()">
            <img src="@/assets/vip/close_icon.png" alt="" />
          </p>
          <p class="text">忘记密码</p>
        </div>
        <div class="form_cont">
          <ul>
            <li>
              <p class="icon"><img src="@/assets/vip/icon3.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="forgetData.name"
                  placeholder="请输入您的姓名"
                />
              </p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/vip/icon4.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="forgetData.phone"
                  maxlength="11"
                  placeholder="请输入您的手机号"
                />
              </p>
            </li>
            <!-- <li>
              <p class="icon"><img src="@/assets/vip/icon5.png" alt="" /></p>
              <p class="input">
                <input type="text" placeholder="请输入您的验证码" />
              </p>
              <p class="btn_num">ABCD</p>
            </li> -->

            <li>
              <p class="icon"><img src="@/assets/vip/icon2.png" alt="" /></p>
              <p class="input">
                <input
                  type="password"
                  v-model="forgetData.password"
                  placeholder="请输入您的密码"
                />
              </p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/vip/icon2.png" alt="" /></p>
              <p class="input">
                <input
                  type="password"
                  v-model="forgetData.reset_password"
                  placeholder="请再次输入您的密码"
                />
              </p>
            </li>
          </ul>
        </div>
        <div class="sign_btn cursor" @click="forgetBtn()">确认修改</div>
        <div class="deep_cont flex">
          <p class="cursor" @click="maskBoxIndex = 1">返回登录</p>
        </div>
      </div>
      <!-- 注册 -->
      <div class="mask_cont_resign mask_cont" v-if="maskBoxIndex == 3">
        <div class="head_text">
          <p class="close" @click="closeMask()">
            <img src="@/assets/vip/close_icon.png" alt="" />
          </p>
          <p class="text">个人会员注册</p>
        </div>
        <div class="form_cont">
          <ul>
            <li>
              <p class="icon"><img src="@/assets/vip/icon1.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="registerData.username"
                  placeholder="请输入您的账号"
                />
              </p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/vip/icon2.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="registerData.password"
                  placeholder="请输入您的密码"
                />
              </p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/vip/icon4.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="registerData.phone"
                  maxlength="11"
                  placeholder="请输入您的手机号"
                />
              </p>
            </li>
            <!-- <li>
              <p class="icon"><img src="@/assets/vip/icon5.png" alt="" /></p>
              <p class="input">
                <input type="text" v-model="registerData" placeholder="请输入您的验证码" />
              </p>
              <p class="btn_num">ABCD</p>
            </li> -->
            <li>
              <p class="icon"><img src="@/assets/vip/icon3.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="registerData.name"
                  placeholder="请输入您的姓名"
                />
              </p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/vip/icon6.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="registerData.company"
                  placeholder="请输入您的单位"
                />
              </p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/vip/icon7.png" alt="" /></p>
              <p class="input">
                <input
                  type="text"
                  v-model="registerData.job"
                  placeholder="请输入您的职务"
                />
              </p>
            </li>
          </ul>
        </div>
        <div class="sign_btn cursor" @click="registerBtn()">注册</div>
        <div class="deep_cont flex">
          <p>已有账号?<span @click="maskBoxIndex = 1">去登录</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { ApiUrl } from "@/utils/util";

export default {
  name: "Bottom",
  data() {
    return {
      maskBoxIndex:1,
      loginData: {
        username: "",
        password: "",
      },
      forgetData: {
        name: "",
        phone: "",
        password: "",
        reset_password: "",
      },
      registerData: {
        username: "",
        password: "",
        phone: "",
        name: "",
        company: "",
        job: "",
      },

      userInfo: "",
    };
  },
  mounted() {
    this.siteInfo = JSON.parse(sessionStorage.getItem("siteInfo"));
  },
  methods: {
    ...mapMutations(["CloseLoginModal", "ShowTipModal"]), //登录弹窗
    // 登录
    loginBtn() {
      if (!this.loginData.username) {
        this.$message.error("请输入您的账号");
        return;
      }
      if (!this.loginData.password) {
        this.$message.error("请输入您的密码");
        return;
      }
      // let form = new FormData(this.loginData);
      // form.append("username", this.loginData.username);
      // form.append("password", this.loginData.password);
      console.log(this.getFormData(this.loginData))
      let form = this.getFormData(this.loginData)
      this.$axios.post(`/user/login`, form).then((res) => {
        if (res.code == 1) {
          this.$message.success("登录成功");
          this.userInfo = res.data
          this.closeMask()
          sessionStorage.setItem('userData',JSON.stringify(res.data))
           location.reload(); //刷新页面
            this.CloseLoginModal();
       } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 忘记密码
    forgetBtn() {
      let reg = /^0?1[3-9][0-9]\d{8}$/;
      if (!this.forgetData.name) {
        this.$message.error("请输入您的姓名");
        return;
      }
      if (!this.forgetData.phone) {
        this.$message.error("请输入您的手机号");
        return;
      }
      if (!reg.test(this.forgetData.phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.forgetData.password) {
        this.$message.error("请输入您的密码");
        return;
      }
      if (!this.forgetData.reset_password) {
        this.$message.error("请再次输入您的密码");
        return;
      }
      if (this.forgetData.password != this.forgetData.reset_password) {
        this.$message.error("两次密码不一致");
        return;
      }
       let form = this.getFormData(this.forgetData)
      this.$axios.post(`/user/reset_password`, form).then((res) => {
        if (res.code == 1) {
          this.$message.success("修改成功");
          this.maskBoxIndex=1
          for (let i in this.forgetData) {
            this.forgetData[i] = "";
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 注册
    registerBtn() {
      let reg = /^0?1[3-9][0-9]\d{8}$/;
      if (!this.registerData.username) {
        this.$message.error("请输入您的账号");
        return;
      }
      if (!this.registerData.password) {
        this.$message.error("请输入您的密码");
        return;
      }
      if (!this.registerData.phone) {
        this.$message.error("请输入您的手机号");
        return;
      }
      if (!reg.test(this.registerData.phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.registerData.name) {
        this.$message.error("请输入您的姓名");
        return;
      }
      if (!this.registerData.company) {
        this.$message.error("请输入您的单位");
        return;
      }
      if (!this.registerData.job) {
        this.$message.error("请输入您的职务");
        return;
      }
      let form = this.getFormData(this.registerData)
      this.$axios.post(`/user/register`, form).then((res) => {
        if (res.code == 1) {
          this.$message.success("注册成功");
         this.maskBoxIndex=1
          for (let i in this.registerData) {
            this.registerData[i] = "";
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 退出登录
    logOutBtn() {
      this.userInfo=''
      sessionStorage.removeItem('userData')
    },
    // 关闭mask弹框
    closeMask() {
      this.maskBoxState = false;
      for (let i in this.loginData) {
        this.loginData[i] = "";
      }
      for (let i in this.registerData) {
        this.registerData[i] = "";
      }
      for (let i in this.forgetData) {
        this.forgetData[i] = "";
      }
      this.CloseLoginModal()
    },

    getFormData(object) {
      const formData = new FormData();
      Object.keys(object).forEach((key) => {
        const value = object[key];
        if (Array.isArray(value)) {
          value.forEach((subValue, i) =>
            formData.append(key + `[${i}]`, subValue)
          );
        } else {
          formData.append(key, object[key]);
        }
      });
      console.log(formData,'formData')
      return formData;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// 会员中心弹框
.mask_box {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  .mask_cont {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 5px 5px 5px 5px;
    box-sizing: border-box;
    padding-bottom: 40px;
    .head_text {
      width: 100%;
      p.close {
        margin: 24px 24px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      p.text {
        height: 37px;
        font-size: 28px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #3467c4;
        margin-left: 32px;
      }
    }
    .sign_btn {
      width: 412px;
      height: 47px;
      background: #3467c4;
      border-radius: 5px 5px 5px 5px;
      margin: 0 auto;
      text-align: center;
      line-height: 47px;
      font-size: 21px;
      color: #ffffff;
    }
    .form_cont {
      padding: 0 32px;
      margin: 30px 0 50px;
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 412px;
          height: 69px;
          background: #ffffff;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          border: 1px solid #dcdcdc;
          margin-bottom: 14px;
          box-sizing: border-box;
          padding: 0 30px 0 15px;
          p.icon {
            img {
              width: 40px;
            }
          }
          p.input {
            flex: 1;
            margin-left: 10px;
            input {
              width: 100%;
              font-size: 16px;
            }
          }
          .btn_num {
            margin-left: 40px;
            margin-right: 10px;
            width: 87px;
            height: 46px;
            background: #9fb2c7;
            border-radius: 5px 5px 5px 5px;
            text-align: center;
            line-height: 46px;
            color: #ffffff;
            font-size: 20px;
          }
        }
      }
    }
    .deep_cont {
      padding: 14px 32px;
      justify-content: space-between;
      p {
        font-size: 18px;
        color: #c3c3c3;
        span {
          color: #3467c4;
          cursor: pointer;
        }
      }
    }
  }
  .mask_cont_box {
    width: 476px;
  }
  // 忘记密码
  .mask_cont_forget {
    .deep_cont {
      width: 100%;
      justify-content: center;
      margin-top: 14px;
      p {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #3467c4;
        text-align: center;
      }
    }
  }
  // 注册
  .mask_cont_resign {
    width: 903px;
    .head_text {
      p.text {
        text-align: center;
      }
    }
    .form_cont {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .deep_cont {
      justify-content: center;
    }
  }
}
</style>
