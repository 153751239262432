<template>
  <div class="home">
    <!-- <header-menu v-if="headerState" :logo="logoUrl" /> -->
    <router-view />
    <foot-menu
      v-if="footerState"
      :deepLogoUrl="deepLogoUrl"
      :class="deepCont == '1' ? 'deepStyle' : ''"
    />
  </div>
</template>
<script>
// import headerMenu from "@/components/m/header.vue";
import footMenu from "@/components/m/footer.vue";

export default {
  name: "pc-home",
  components: {
    // headerMenu,
    footMenu,
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      logoUrl: "",
      deepLogoUrl: "",
      deepCont: "2",
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        // const { headerIsShow, footerIsShow } = newVal.meta;
        // this.headerState = headerIsShow ? false : true;
        // this.footerState = footerIsShow ? false : true;

        console.log(newVal, "ppp");
        if (newVal.path == "/lawServe") {
          this.deepCont = "1";
        } else {
          this.deepCont = "2";
        }
        if (newVal) {
          document.body.scrollTop = 80;
          document.documentElement.scrollTop = 80;
        }
      },
      // immediate: true,
    },
  },
  mounted() {
    // this.getWebsiteInfo(); //获取网站配置信息
    this.urlState = window.location.host.includes("form_data")
      ? "false"
      : "true";
      // 是否给footer加格外样式
    if (window.location.href.includes("lawServe")) {
      this.deepCont = "1";
    } else {
      this.deepCont = "2";
    }
  },
  methods: {},
};
</script>

<style lang="scss">
html {
  font-size: 14px;
}
div {
  font-size: 14px;
}
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
.overflow_o {
  text-overflow: ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  white-space: nowrap; /*让文字不换行*/
  overflow: hidden; /*超出要隐藏*/
}

.fixed_bgm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// tab切换菜单
.menu_sec_list {
  position: fixed;
  left: 0;
  top: 78px;
  width: 100%;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 2px rgba(161, 196, 231, 0.16);
  box-sizing: border-box;
  z-index: 9;
  ul {
    margin: 0 66px;
    justify-content: space-between;
    li {
      line-height: 90px;
      font-size: 26px;
      color: #333333;
      flex: 1;
      text-align: center;
      position: relative;
    }
    li.active {
      color: #3467c4;
      font-weight: bold;
    }
    li.active::after {
      position: absolute;
      content: "";
      width: 80%;
      height: 4px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: #3467c4;
      display: inline-block;
      border-radius: 20px;
    }
  }
}
.m_list_cont {
  div.tips_box {
    position: relative;
    p.title {
      text-align: center;
      font-size: 36px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #3467c4;
      margin-bottom: 40px;
    }
    p.more {
      position: absolute;
      top: 10px;
      right: 0;
      font-size: 26px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 332px;
      margin-bottom: 30px;
      padding-bottom: 30px;
      background: #ffffff;
      box-shadow: 0px 6px 30px 2px rgba(161, 196, 231, 0.2);
      p.img {
        width: 100%;
        height: 186px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p.title {
        margin: 8px 16px 0;
        font-size: 26px;
        color: #333333;
      }
    }
    li:nth-child(2n) {
      margin-left: 30px;
    }
  }
}

.M_form_box {
  position: fixed;
  width: 90%;
  // height: 578px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  padding: 30px 35px;
  z-index: 99;
  .close_btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 40px;
    top: 40px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .title_cont {
    justify-content: space-between;
    p.tips {
      font-size: 36px;
      font-weight: bold;
      color: #3467c4;
    }
    .change_text {
      cursor: pointer;
      span {
        font-size: 14px;
        color: #0065df;
      }
      img {
        width: 18px;
        height: 18px;
        margin-left: 4px;
      }
    }
  }
  .tips_text {
    margin-top: 20px;
    width: 395px;
    height: 36px;
    background: #f5f5f5;
    span {
      line-height: 36px;
      padding-left: 20px;
      font-size: 14px;
      color: #8c8c8c;
      // display: block;
    }
  }
  .form_cont {
    margin-top: 38px;

    p.btn {
      width: 610px;
      height: 80px;
      background: #3467c4;
      border-radius: 10px 10px 10px 10px;
      text-align: center;
      line-height: 80px;
      color: #fff;
      font-size: 28px;
      margin-top: 53px;
      cursor: pointer;
    }
    p.forget_pass {
      font-size: 14px;
      color: #a8abb2;
      text-align: center;
      margin-top: 14px;
      cursor: pointer;
    }
  }
  textarea {
    resize: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 15px;
    width: 100%;
  }
}
</style>
