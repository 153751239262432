<template>
  <div class="home">
    <header-menu v-if="headerState" :logo="logoUrl" />
    <router-view />
    <foot-menu v-if="footerState" :deepLogoUrl="deepLogoUrl" />
  </div>
</template>
<script>
import headerMenu from "@/components/pc/header.vue";
import footMenu from "@/components/pc/footer.vue";

export default {
  name: "pc-home",
  components: {
    headerMenu,
    footMenu,
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      logoUrl: "",
      deepLogoUrl: "",
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        
        // const { headerIsShow, footerIsShow } = newVal.meta;
        // this.headerState = headerIsShow ? false : true;
        // this.footerState = footerIsShow ? false : true;
        // if(newVal){
        //   document.body.scrollTop = 80;
        //   document.documentElement.scrollTop = 80;
        // }
        
      },
      // immediate: true,
    },
  },
  mounted() {
    // this.getWebsiteInfo(); //获取网站配置信息
    this.urlState = window.location.host.includes("form_data")
      ? "false"
      : "true";
  },
  methods: {
    getWebsiteInfo() {
      //获取网站配置信息
      let url = "/v1/websiteInfo";
      if (window.location.host == "zzcl.kjcxchina.com") {
        url = "/v1/websiteInfo";
      }
      if (window.location.host == "zzjt.kjcxchina.com") {
        url = "/v1/groupWebsiteInfo";
      }

      this.$axios.post(url, { is_pc: 2 }).then((res) => {
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        localStorage.setItem("navigation", JSON.stringify(res.data.navigation));
        // document.getElementById("linIcon").href = res.data.site_info.site_icon;
        // document.getElementById("titleName").innerHTML = res.data.site_info.name;
        // this.logoUrl = res.data.site_info.site_logo || require('@/assets/pc/logo.png');
        // this.deepLogoUrl = res.data.site_info.footer_logo || require('@/assets/pc/footer_logo.png');
      });
    },
  },
};
</script>

<style lang="scss">
html {
  font-size: 14px;
}
div {
  font-size: 14px;
}
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
.overflow_o {
  text-overflow: ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  white-space: nowrap; /*让文字不换行*/
  overflow: hidden; /*超出要隐藏*/
}

.fixed_bgm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// 列表
 .title_box {
      p.text {
        width: 100%;
        text-align: center;
        font-size: 28px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #3467C4;
      }
      p.more {
        position: absolute;
        right: 0;
        cursor: pointer;
        font-size: 18px;
        color: #999999;
      }
    }
.cont_list {
  width: 1200px;
  position: relative;
  margin: 0 auto 100px;
  ul {
    margin-top: 27px;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 285px;
      // height: 248px;
      height: 208px !important;
      background: #ffffff;
      box-shadow: 0px 3px 15px 1px rgba(161 ,196, 231, 0.2);
      margin-right: 20px;
      box-sizing: border-box;
      margin-bottom: 30px;
      p.img {
        width: 285px;
        height: 159px;
        background: #3467C4;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p.title {
        margin-top: 8px;
        padding: 0 12px;
        font-size: 16px;
      }
      .cont_text {
        padding: 9px 12px 0;
        display: flex;
        display: none !important;
        p.desc {
          flex: 1;
          font-size: 14px;
          color: #999999;
        }
      }
    }
    li:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>
