<template>
  <div class="footer_box">
    <ul>
        <li>主办单位：广州市员村工人文化宫</li>
        <li>建设单位：广东中工云</li>
        <li>地址：广东省广州市天河区员村二横路2号</li>
        <li>邮编：510655</li>
    </ul>
    <p class="deep_code">
        Copyright 2015-2020 kjcxchina.com All Rights Reserved
京ICP备16012224号
    </p>
  </div>
</template>
<script>

export default {
  name: "footer_box",
  components: {
    // loginWay,
    // Tip,
  },
 
 
  data() {
    return {
     
     
    };
  },
  mounted() {
   
   
  },
  methods: {
    
    
  },
  
};
</script>

<style lang="scss" scoped>
.footer_box{
    width: 750px;
    height: 388px;
    background: #333333;
    box-sizing: border-box;
    ul{
        padding-top: 60px;
        li{
            text-align: center;
            font-size: 26px;
            color: #FFFFFF;
            line-height: 48px;
        }
    }
    p.deep_code{
        margin: 26px 28px;
        text-align: center;
        font-size: 26px;
        color: #999999;
    }
}


</style>
