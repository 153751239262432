<template>
  <div class="footer_box">
    <div class="foot_cont">
      
      <div class="adr_cont ">
        <p>主办单位：广州市员村工人文化宫   </p>
        <p>建设单位：广东中工云</p>
        <p>地址：广东省广州市天河区员村二横路2号   邮编：510655   京ICP备16012224号</p>
      </div>
      <div class="copy flex">
        <p class="text">Copyright 2023 kjcxchina.com All Rights Reserved</p>
        <!-- <ul class="flex">
          <li>网站地图<span>|</span></li>
          <li>联系我们<span>|</span></li>
          <li>手机观看</li>
        </ul> -->
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "pc-footer",
  components: {
    // loginWay,
    // Tip,
  },
 
 
  data() {
    return {
     
     
    };
  },
  mounted() {
   
   
  },
  methods: {
    
    
  },
  
};
</script>

<style lang="scss" scoped>
.footer_box{
  min-height: 160px;
  background: #333333;
  overflow: hidden;
  .foot_cont{
    width: 1200px;
    margin: 80px auto 52px;
    .copy{
      margin-top: 16px;
      justify-content: space-between;
      p.text{
        font-size: 14px;
        color: #999999;
        text-align: center;
        width: 100%;
      }
      // ul{
      //     li{
      //       font-size: 16px;
      //       color: #FFFFFF;
      //       span{
      //         padding: 0 5px;
      //       }
      //     }
          
      // }
    }
    .adr_cont{
      font-size: 14px;
      color: #FFFFFF;
      p{
        margin-right: 50px;
        text-align: center;
        line-height: 24px;
      }
    }
  }
}



</style>
